/**
 * Retrieves the range of values for a given class based on its interval.
 *
 * @param {Object} interval - The object containing the min and max values.
 * @param {number} minInterval - The minimum interval value to consider.
 * @param {number} maxInterval - The maximum interval value to consider.
 * @returns {number[]} An array containing the start and end of the range.
 */

export const getClassRange = (interval, minInterval, maxInterval) => {
  const min = typeof interval.min === 'number' ? interval.min : minInterval
  const max = typeof interval.max === 'number' ? interval.max : maxInterval

  return isNaN(min) || isNaN(max) ? [minInterval, maxInterval] : [min, max]
}

/**
 * The threshold value used to determine the minimum spacing between tick marks.
 */
export const threshold = 0.01

/**
 * Filters an array of tick positions to remove overlapping ticks based on a specified threshold.
 *
 * @param {number[]} ticks - An array of tick positions to filter.
 * @param {number} threshold - The minimum spacing between ticks. Ticks within this distance from the last accepted tick will be excluded.
 * @returns {number[]} An array of filtered tick positions that are spaced apart by at least the specified threshold.
 */
export const filterOverlappingTicks = (ticks, threshold) => {
  const filteredTicks = []
  let lastTick = null

  ticks.forEach(tick => {
    if (lastTick === null || tick - lastTick > threshold) {
      filteredTicks.push(tick)
      lastTick = tick
    }
  })

  return filteredTicks
}
