// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Molto Basso",
  "% Mapa alertas": "% Mappa di avviso",
  "% Media Fosforo": "% Media Fosforo",
  "% Media Nitrogeno": "% Media Azoto",
  "% Media Potasio": "% Media Potassio",
  "% resiembra": "% Risemina",
  "0.10 Low": "0.10 Basso",
  "0.20 Very Low": "0.20 Molto Basso",
  "0.25 Very Low": "0.25 Molto Basso",
  "0.30 Low": "0.30 Basso",
  "0.30 Medium": "0.30 Medio",
  "0.40  Low": "0.40  Basso",
  "0.40 Medium": "0.40 Medio",
  "0.45 High": "0.45 Alto",
  "0.50 High": "0.50 Alto",
  "0.55 Medium": "0.55 Medio",
  "0.65 Very High": "0.65 Molto Alto",
  "0.70 High": "0.70 Alto",
  "0.85 Very High": "0.85 Molto Alto",
  "abonado de cobertera": "Fertilizzazione di copertura",
  "abonado de fondo": "Fertilizzazione di base",
  "abonado dosificado": "Fertilizzazione dosata",
  "abonado tasa fija": "Fertilizzazione a tasso fisso",
  "abonado zonificado": "Fertilizzazione zonale",
  "accumulated vegetative growth": "Crescita vegetativa accumulata",
  "Acre": "Acre",
  "active": "attivo",
  "Add at least one recipient (+)": "Aggiungi almeno un destinatario (+)",
  "Add Field Button": "Pulsante Aggiungi Campo",
  "Add Field Tutorial": "Tutorial Aggiungi Campo",
  "add location": "aggiungi posizione",
  "Add new sample": "Aggiungi nuovo campione",
  "add photo": "aggiungi foto",
  "added to pending": "aggiunto a in sospeso",
  "Advanced": "Avanzato",
  "advanced season parameters": "parametri stagionali avanzati",
  "afección": "condizione",
  "afección extensivo": "condizione estensiva",
  "Affected Surface": "Superficie Colpita",
  "After filling the form, you can draw the field": "Dopo aver compilato il modulo, puoi disegnare il campo",
  "agrodelivery": "Agrodelivery",
  "all": "tutto",
  "ALMENDRA": "MANDORLA",
  "alto estrés hídrico": "alto stress idrico",
  "alto pot prod": "alta potenzialità produttiva",
  "ambiente": "ambiente",
  "An error occured": "Si è verificato un errore",
  "An error occurred from the server. Please try again": "Si è verificato un errore dal server. Per favore riprova",
  "And that's it!": "Ecco fatto!",
  "anexo información": "informazioni in allegato",
  "Applied filters": "Filtri applicati",
  "Apply": "Applica",
  "apply date filter": "applica filtro data",
  "Apply selection": "Applica selezione",
  "Are you sure to close the editor?": "Sei sicuro di chiudere l'editor?",
  "Are You sure?": "Sei sicuro?",
  "area": "area",
  "Area": "Area",
  "ATR": "Zucchero Recuperabile Totale",
  "author": "autore",
  "Automatic harvest": "Raccolta automatica",
  "auxiliary files": "file ausiliari",
  "back": "indietro",
  "bajo estrés hídrico": "basso stress idrico",
  "bajo pot prod": "bassa potenzialità produttiva",
  "Base temperature": "Temperatura di base",
  "baseLayer": "Strato di base",
  "Basic": "Base",
  "basic season parameters": "parametri stagionali di base",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"macchia dell'occhio\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"macchia dell'occhio\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"macchia dell'occhio\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"macchia dell'occhio\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"macchia dell'occhio\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "I punti iniziale e finale della linea di taglio devono essere fuori dal poligono/cerchio",
  "Buried": "Sepolto",
  "calendar": "Calendario",
  "calibre": "Calibro",
  "camera error message": "Sembra che ci sia un problema di permessi con la camera",
  "camera_unavailable": "camera non disponibile",
  "Can't modify field with active planet subscription": "Non è possibile modificare il campo con l'abbonamento planet attivo",
  "cancel": "annulla",
  "cane_num": "numero di canne",
  "cantidad": "quantità",
  "capa base": "strato di base",
  "Capture screenshot": "Cattura screenshot",
  "Change opacity": "Cambia opacità",
  "Choose your layer": "Scegli il tuo strato",
  "Choose your tool": "Scegli il tuo strumento",
  "Circle Drawing": "Disegno del cerchio",
  "class": "classe",
  "Class values must be numerical": "I valori delle classi devono essere numerici",
  "classes": "Classi",
  "classes number": "numero di classi",
  "clear": "pulisci",
  "Clear all": "Pulisci tutto",
  "clear form": "pulisci modulo",
  "clear selected fields": "pulisci i campi selezionati",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Cliccando questo pulsante, puoi alternare la visibilità dello strato selezionato.",
  "clientDeviation": "deviazione del cliente",
  "Close": "Chiudi",
  "Close editor": "Chiudi editor",
  "Close selection": "Chiudi selezione",
  "Close the temporal Comparison": "Chiudi il confronto temporale",
  "close_selected": "chiudi selezionato",
  "closed": "chiuso",
  "cloud": "Nuvola",
  "Cloud Coverage": "Copertura nuvolosa",
  "comment": "commento",
  "comp. temporal target": "obiettivo temporale comp.",
  "comp. temporal variabilidad": "variabilità temporale comp.",
  "comparación de conteo": "Confronto del conteggio (%)",
  "comparación de estado hídrico": "Confronto dello stato idrico (%)",
  "comparación de estado hídrico drone": "Confronto dello stato idrico - Drone (%)",
  "comparación de estado hídrico sat": "Confronto dello stato idrico - SAT (%)",
  "comparación de estimación de producción": "Confronto della stima della produzione (%)",
  "comparación de fósforo": "Confronto del fosforo (%)",
  "comparación de mapa de alertas": "Confronto della mappa di avviso (%)",
  "comparación de mapa de faltas": "Confronto della mappa delle mancanze (%)",
  "comparación de media de conteo": "Confronto della media del conteggio (%)",
  "comparación de media de estado hídrico": "Confronto della media dello stato idrico (%)",
  "comparación de media de fósforo": "Confronto della media del fosforo (%)",
  "comparación de media de mapa de alertas": "Confronto della media della mappa di avviso (%)",
  "comparación de media de mapa de faltas": "Confronto della media della mappa delle mancanze (%)",
  "comparación de media de nitrógeno": "Confronto della media dell'azoto (%)",
  "comparación de media de potasio": "Confronto della media del potassio (%)",
  "comparación de media de producción total": "Confronto della media della produzione totale (%)",
  "comparación de media de rendimiento graso": "Confronto della media della resa grassa (%)",
  "comparación de medias de estimación de producción": "Confronto delle medie della stima della produzione (%)",
  "comparación de medias de ndvi": "Confronto delle medie del ndvi (%)",
  "comparación de medias de proteína": "Confronto delle medie della proteina (%)",
  "comparación de medias de variabilidad": "Confronto delle medie della variabilità (%)",
  "comparación de ndvi": "Confronto del ndvi (%)",
  "comparación de nitrógeno": "Confronto dell'azoto (%)",
  "comparación de nitrógeno relativo": "Confronto dell'azoto relativo(%)",
  "comparación de potasio": "Confronto del potassio (%)",
  "comparación de producción total": "Confronto della produzione totale (%)",
  "comparación de proteína": "Confronto della proteina (%)",
  "comparación de rendimiento graso": "Confronto della resa grassa (%)",
  "comparación de sacarosa": "Confronto della saccarosio",
  "comparación de variabilidad": "Confronto della variabilità (%)",
  "confirm": "Conferma",
  "Congratulations! Your field has been created.": "Congratulazioni! Il tuo campo è stato creato",
  "Congratulations! Your field has been edited.": "Congratulazioni! Il tuo campo è stato modificato",
  "Contact": "Contatto",
  "Contenido de sacarosa ( % )": "Contenuto di saccarosio ( % )",
  "conteo - pdf rgb": "conteggio - pdf rgb",
  "conteo - shps": "conteggio - shps",
  "conteo de individuos": "Conteggio degli Individui",
  "conteo lechugas": "Conteggio Lattughe",
  "continue": "continua",
  "Cookies and Privacy": "Cookie e privacy",
  "CORN": "MAIS",
  "correction": "Correzione",
  "Corte nitrogeno": "Taglio azoto",
  "cost": "Costo",
  "count (units)": "Conteggio (Unità)",
  "Counting": "Contando",
  "create": "crea",
  "Create New Field": "Crea nuovo campo",
  "CREATE_RANGES": "Sulla base dell'istogramma dell'immagine, creare intervalli con una percentuale di dati simili in ciascun intervallo.",
  "created": "Creato",
  "crecimiento cultivo": "Crescita del coltivo",
  "Crop": "Coltura",
  "Crop type": "Tipo di coltura",
  "CROP_INSPECTION": "Ispezione del raccolto",
  "cultive": "Coltiva",
  "Cultivo": "Coltura",
  "current season": "stagione corrente",
  "current selection could take a moment to process": "L'intervallo di date corrente è stato aumentato, la richiesta potrebbe richiedere un momento per essere elaborata",
  "Customer": "Cliente",
  "customerProductionEstimation": "Stima della produzione del cliente",
  "customers": "Clienti",
  "cut": "Taglia",
  "Cutter": "Taglierina",
  "dashboard": "Cruscotto",
  "date": "Data",
  "DD/MM/YYYY": "DD/MM/YYYY",
  "delete": "elimina",
  "Delete": "Elimina",
  "Delete downloaded report": "Elimina il rapporto scaricato",
  "delete downloaded sample": "elimina campione scaricato",
  "Delete field": "Elimina campo",
  "delete layers?": "elimina strati?",
  "delete sample": "elimina campione",
  "delete sample edition": "elimina edizione campione",
  "DeleteField": "Il campo è stato eliminato",
  "Deliverables tutorial": "Tutorial: consegne",
  "Description": "Descrizione",
  "Deselect": "Deseleziona",
  "deselect all": "Deseleziona tutto",
  "deviation": "Deviazione",
  "Did you forget your password ?": "Hai dimenticato la tua password?",
  "disponible en visor": "Disponibile nel visore",
  "do you want to delete": "vuoi eliminare",
  "documentation": "Documentazione",
  "don't show again": "non mostrare di nuovo",
  "Done": "Fatto",
  "dosificación genérica": "Dosaggio generico",
  "Dosis Media Cliente": "Dose media cliente",
  "Dosis media Propuesta": "Dose media proposta",
  "dosis riego": "Dose di irrigazione",
  "Download": "Scarica",
  "download failed": "I seguenti download non sono stati completati",
  "Download list": "Lista di download",
  "Download selected fields samples": "Scarica campioni dei campi selezionati",
  "download selected geometries": "Scarica le geometrie selezionate",
  "download values": "Scarica valori",
  "downloaded": "Scaricato",
  "downloading layers": "scaricando strati",
  "downloads": "Download",
  "Drag and drop your layers to arrange them in the list.": "Trascina e rilascia i tuoi strati per organizzarli nell'elenco",
  "Drag Fields": "Trascina Campi",
  "Drag your layers": "Trascina i tuoi strati",
  "Draw Field": "Disegna campo",
  "dron": "Drone",
  "Dron sectores": "Settori Drone",
  "drop here": "rilascia qui",
  "Dry": "Secco",
  "dry matter": "Materia secca",
  "dryMatterField": "Campo di materia secca",
  "dryMatterLaboratory": "Laboratorio di materia secca",
  "dsm": "DSM",
  "earliest harvest date": "Data di raccolta più precoce",
  "edit": "modifica",
  "Edit Field": "Modifica campo",
  "Edit geometry": "Modifica geometria",
  "edit range": "modifica intervallo",
  "Edit values": "Modifica valori",
  "ej 100": "Ej 100",
  "End Date": "Data di fine",
  "Equal Interval": "Intervallo Uguale",
  "Equidistant ranges": "Intervalli equidistanti",
  "error fetching classes": "Il cielo è troppo nuvoloso nella data selezionata. Regola il filtro delle nuvole nel calendario per trovare una giornata più serena.",
  "estado hídrico": "Stato idrico",
  "estado hídrico (ndmi)": "Stato idrico (NDMI)",
  "estado hidrico sat": "Stato idrico sat",
  "estimación": "Stima",
  "estimación producción": "Stima della produzione",
  "Estimacion produccion ( Tn / Ha )": "Stima della produzione ( Tn / Ha )",
  "estimación producción cualitativa": "Stima qualitativa della produzione",
  "estimación proteína": "Stima della proteina",
  "Estimacion proteina ( % )": "Stima della proteina ( % )",
  "estimación sacarosa": "Stima della saccarosio",
  "estimación sacarosa ": "Stima della saccarosio",
  "estimación sacarosa cualitativa": "Stima qualitativa della saccarosio",
  "Estimacion Total ( Kg )": "Stima totale ( Kg )",
  "estimation gauging": "Stima del calibro",
  "estrategia de dosificacion": "Strategia di dosaggio",
  "estrés hídrico (lws)": "Stress idrico (LWS)",
  "evolución de NDVI": "Evoluzione del NDVI",
  "evolution": "evoluzione",
  "Ex : comment": "Esempio: commento",
  "Ex : field R02": "Esempio: campo R02",
  "Ex : Tresbolillos, row, tree": "Esempio: Tresbolillos, fila, albero",
  "expected_final_weight": "Peso finale previsto",
  "external_id": "ID esterno",
  "extra parameters": "parametri extra",
  "falhas carreadores": "Buchi nei vettori",
  "falhas internas": "Buchi interni",
  "falhas terraços": "Buchi nei terrazzi",
  "fat performance": "Rendimento grasso",
  "faults map": "Mappa dei difetti",
  "fdn": "FDN",
  "Fecha de vuelo": "Data del volo",
  "Fecha Plantacion": "Data di piantagione",
  "Feddan": "Feddan",
  "fertilization strategy": "Strategia di fertilizzazione",
  "Fertilizer recommendation": "Raccomandazione di fertilizzante",
  "field": "campo",
  "Field Name": "Nome del campo",
  "Field without geometry": "Campo senza geometria",
  "FIELD_FILTER": "Filtri del campo",
  "field_name": "Nome del campo",
  "FIELD_TEMPORAL_EVOLUTION": "Evoluzione temporale del campo",
  "fields": "campi",
  "Fields without geometries": "Campi senza geometrie",
  "File cannot be generated due to high amounts of clouds. Please select another date": "Il file non può essere generato a causa della grande quantità di nuvole. Si prega di selezionare un'altra data",
  "File generator": "Generatore di file",
  "File Generator": "Generatore di file",
  "File name": "Nome del file",
  "file type": "Tipo di file",
  "file-is-not-available-in-selected-language": "Questo file non è disponibile nella lingua selezionata. Generato in inglese.",
  "Fill plantation date of current field, showing one year of historic data": "Riempi la data di piantagione del campo corrente, mostrando un anno di dati storici",
  "Fill the field form": "Compila il modulo del campo",
  "Fill the form to apply for all our services": "Compila il modulo per candidarti a tutti i nostri servizi",
  "filtered field": "campo filtrato",
  "filtered fields": "campi filtrati",
  "Filters": "Filtri",
  "final_weight": "Peso finale",
  "fitopatógenos": "Fitopatogeni",
  "Flight Slider": "Cursore di volo",
  "FLIGHT_FILTER": "Confronto temporale",
  "flights type": "Tipo di voli",
  "fmsavi": "FMSAVI",
  "for everyone": "per tutti",
  "for me": "per me",
  "forecasts": "Previsioni",
  "formulario de abonado": "Modulo di fertilizzazione",
  "formulario de dosis": "Modulo di dosaggio",
  "fósforo foliar": "Fosforo fogliare",
  "fr": "Franco",
  "Fraction": "Frazione",
  "free": "gratuito",
  "from": "da",
  "fruits_100_weight": "Peso 100 frutti",
  "fruits_actual1_weight": "Peso attuale dei frutti",
  "Fuente de informacion": "Fonte d'informazione",
  "gallery": "galleria",
  "gauging date": "Data di calibrazione",
  "generation error": "Errore di generazione",
  "geometry": "geometria",
  "get a free trial": "ottieni una prova gratuita",
  "Get back to editor": "Torna all'editor",
  "global files": "Scarica file di pre-elaborazione, allenamento e previsione PDF e CSV",
  "global layer": "Strato globale",
  "gm_cut_number": "Numero di tagli",
  "gm_data_generation": "Data satellitare (settimane)",
  "gm_day_cycle": "Giorni di coltivazione",
  "gm_harvest_estimated_date": "Data stimata del raccolto (settimane)",
  "gm_hemav_atr_estimation": "Previsione TRS",
  "gm_hemav_pol_estimation": "Previsione di polarizzazione",
  "gm_hemav_prodph_estimation": "Previsione di rendimento",
  "gm_hemav_realprodph_lastseason": "Rendimento reale della stagione precedente",
  "gm_hemav_sac_estimation": "Previsione di saccarosio",
  "gm_luvi_date_generation": "Data satellitare - LUVI (settimane)",
  "gm_luvi_day_cycle": "Giorni di coltivazione (NDVI-LUVI)",
  "gm_luvi_mean": "Vigore vegetativo (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Variabilità (NDVI-LUVI)",
  "gm_lws_mean": "Stress idrico (LWS)",
  "gm_ndmi_date_generation": "Data satellitare - NDMI (settimane)",
  "gm_ndmi_day_cycle": "Giorni di coltivazione (NDMI)",
  "gm_ndre_date_generation": "Data satellitare - NDRE (settimane)",
  "gm_ndre_day_cycle": "Giorni di coltivazione (NDRE)",
  "gm_sar_biomass_cat": "Biomassa categorica (SAR)",
  "gm_season_label": "Stagione",
  "gm_sub_type_name": "Varietà",
  "gm_type_name": "Coltura",
  "go to field": "Vai al campo",
  "grid": "griglia",
  "harvest date": "Data di raccolta",
  "harvest planning": "Pianificazione del raccolto",
  "HARVEST_PLANNING_TOOL": "Strumento di pianificazione del raccolto",
  "HARVEST_PREDICTION": "Previsione del raccolto",
  "Hectare": "Ettaro",
  "Hemav Default": "Predefinito Hemav",
  "HEMAV Default": "Predefinito HEMAV",
  "Here, you can play with the preview of your different layers.": "Qui, puoi giocare con l'anteprima dei tuoi diversi strati",
  "hide": "nascondi",
  "Hide All Layers": "Nascondi tutti gli strati",
  "hide filter list": "nascondi lista filtri",
  "Hide Global Layer": "Nascondi strato globale",
  "Hide Measure Tools": "Nascondi strumenti di misurazione",
  "high": "Alto",
  "high level": "Livello alto",
  "high need": "Alto bisogno",
  "higher clay content": "Contenuto più alto di argilla",
  "humedad de suelo - beta (%)": "Umidità del suolo - Beta (%)",
  "humedad vegetativa (ndmi)": "Umidità vegetativa (NDMI)",
  "Hybrid View": "Vista ibrida",
  "hydric status": "Stato idrico - NDMI",
  "hydric status drone": "Stato idrico - Drone",
  "If foliar sampler haven't been made, the date you must enter is the current": "Se il campionatore fogliare non è stato effettuato, la data da inserire è quella attuale",
  "If you have two or more flights, you can compare them temporally using this tool.": "Se hai due o più voli, puoi confrontarli temporalmente usando questo strumento.",
  "If you have undone an action, you can press this button to redo it.": "Se hai annullato un'azione, puoi premere questo pulsante per rifarla.",
  "image": "immagine",
  "Impacto estimado": "Impatto stimato",
  "In this tab, you can download the generated PDFs.": "In questa scheda, puoi scaricare i PDF generati.",
  "individuals_num": "Numeri degli individui",
  "infection": "infezione",
  "info": "Informazioni",
  "Information": "Informazione",
  "informe": "Relazione",
  "integración con maquinaria": "Integrazione con la macchinaria",
  "Invalid credentials": "Credenziali non valide",
  "invalid date": "Data non valida",
  "Invalid email": "Email non valida",
  "invest": "investire",
  "Irrigation recommendation": "Raccomandazione per l'irrigazione",
  "Irrigation type": "Tipo di irrigazione",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "Comunemente si regola per stabilire la quantità di fertilizzante o semi da applicare per unità di superficie.",
  "item_num": "Numero dell'oggetto",
  "Keep selection": "Mantieni selezione",
  "label": "01.Etichetta",
  "Label": "Etichetta",
  "languaje": "Linguaggio",
  "last sample": "Ultimo campione",
  "lat": "Latitudine",
  "layers": "Strati",
  "layers estrés hídrico (lws)": "Strati di stress idrico (LWS)",
  "layers status": "Stato degli strati",
  "Legal limitation": "Limitazione legale",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"macchia anulare\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"macchia anulare\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"macchia anulare\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"macchia anulare\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"macchia anulare\" - g5",
  "letter only": "Solo lettere",
  "linear meters": "metri lineari",
  "líneas de cultivo": "Linee di coltivazione",
  "list": "lista",
  "loading": "caricamento",
  "location": "posizione",
  "Log in": "Accedi",
  "logout": "Disconnetti",
  "lon": "Longitudine",
  "looks like its taking too long": "sembra che stia prendendo troppo tempo",
  "low": "Basso",
  "low level": "Livello basso",
  "low need": "Basso bisogno",
  "lower clay content": "Contenuto più basso di argilla",
  "LUVI variability": "Variabilità LUVI",
  "Machines Type": "Tipo di macchine",
  "main_macronutrient": "Macronutriente principale",
  "malas hierbas": "Erbe infestanti",
  "malas hierbas - hoja estrecha": "Erbe infestanti - Foglia stretta",
  "malas hierbas - hoja larga": "Erbe infestanti - Foglia larga",
  "malas hierbas - zonificado": "Erbe infestanti - Zonizzate",
  "malas hierbas shps": "Erbe infestanti SHPs",
  "malas hierbas shps - hoja estrecha": "Erbe infestanti SHPs - Foglia stretta",
  "malas hierbas shps - hoja larga": "Erbe infestanti SHPs - Foglia larga",
  "malas hierbas shps - zonificado": "Erbe infestanti SHPs - Zonizzate",
  "Manage report": "Gestisci rapporto",
  "max 2 years": "2 anni max",
  "max comparable fields": "Massimi campi confrontabili",
  "maximo de variacion": "Massima variazione",
  "máximo vigor dos meses (ndvi)": "Massimo vigore due mesi (NDVI)",
  "máximo vigor un año (ndvi)": "Massimo vigore un anno (NDVI)",
  "Maximun cloud coverage": "Massima copertura nuvolosa",
  "mean": "Media",
  "Measures tool tutorial": "Tutorial: Strumenti di misurazione",
  "Measures tools": "Strumento di misurazione",
  "media de faltas": "Media delle mancanze",
  "medias": "Medie",
  "medio pot prod": "Potenzialità produttiva media",
  "medium": "Medio",
  "medium clay content": "Contenuto medio di argilla",
  "medium level": "Livello medio",
  "medium_weight_bigSize": "Peso medio frutti grandi",
  "medium_weight_mediumSize": "Peso medio frutti medi",
  "medium_weight_smallSize": "Peso medio frutti piccoli",
  "Mensual limit": "Limite mensile",
  "Message": "Messaggio",
  "METEOROLOGICAL_STATISTICS": "Statistiche meteorologiche",
  "mode": "Modalità",
  "model type": "Tipo di modello",
  "Move the slider": "Muovi il cursore",
  "msavi": "MSAVI",
  "N_BUNCHES": "Numero di grappoli",
  "Name": "Nome",
  "nameless field": "Campo senza nome",
  "Natural Breaks (Jenks)": "Rotture Naturali (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "NDVI Reale",
  "ndvi-curvas de nivel": "NDVI-Linee di livello",
  "Network request failed": "Errore di connessione",
  "new sample": "nuovo campione",
  "New sample created.": "Nuovo campione creato.",
  "NITRO": "Azoto",
  "nitrogen chlorophyll (ndre)": "Clorofilla-azoto (NDRE)",
  "nitrogen_assimilable": "Azoto assimilabile",
  "nitrógeno foliar": "Azoto fogliare",
  "Nivel de estres": "Livello di stress idrico",
  "no data": "Nessun dato",
  "no data available": "Nessun dato disponibile ancora",
  "no data with current selection": "Nessun dato con la selezione corrente",
  "No fields selected": "Nessun campo selezionato",
  "no fields to clear": "Nessun campo da pulire",
  "Not a user yet ?": "Non sei ancora un utente?",
  "not enough data to display this chart": "Non ci sono abbastanza dati per visualizzare questo grafico",
  "Not supported by browser": "Non supportato dal tuo browser",
  "notes": "note",
  "number only": "Solo numeri",
  "Numero vuelo": "Conteggio dei voli",
  "observations": "osservazioni",
  "offline access": "accesso offline",
  "offline mode": "Modalità offline",
  "oil_yield": "Rendimento dell'olio",
  "ok": "Ok",
  "Once you are done, click this button to save the changes.": "Quando hai finito, clicca questo pulsante per salvare le modifiche",
  "open": "apri",
  "Open": "Apri",
  "Open selection": "Apri selezione",
  "open temporal comparison": "Apri confronto temporale",
  "open_selected": "apri selezionato",
  "optimize": "ottimizza",
  "or": "o",
  "Order by a - z": "Ordina da a - z",
  "Order by distance to the field": "Ordina per distanza dal campo",
  "Order by z - a": "Ordina da z - a",
  "ourDeviation": "Deviazione di Hemav",
  "ourProductionEstimation": "Stima della produzione di Hemav",
  "Parcela": "Campo",
  "Password": "Password",
  "pb": "Pb",
  "pcd": "PCD",
  "pcd arbol": "PCD albero",
  "pcd interpolado": "PCD interpolato",
  "pcd row": "PCD fila",
  "pdf": "PDF",
  "pending": "in sospeso",
  "phospohorus": "Fosforo",
  "phosporus": "Fosforo (ppm)",
  "photo": "foto",
  "photoid": "ID foto",
  "photoId": "Foto",
  "picture_path": "Percorso immagine",
  "plague": "Piaga",
  "planned dosage": "Dosaggio pianificato",
  "Plant emitters": "Emissori di pianta",
  "plantation date": "Data di piantagione",
  "Plantation date": "Data di piantagione",
  "plantation_end_date_range": "Intervallo di date di piantagione",
  "plantation_start_date_range": "Intervallo di date di piantagione",
  "Plantations": "Piantagioni",
  "plants": "Piante",
  "Plants distance": "Distanza tra le piante",
  "plants_density_range": "Intervallo di densità delle piante",
  "Please wait or order one to have data to visualized.": "Programma un volo per ottenere i risultati per quel campo",
  "Please, choose a field variety": "Per favore, scegli una varietà di campo",
  "Please, choose the field owner": "Per favore, scegli il proprietario del campo",
  "plural selected": "selezionato",
  "POL": "Polarizzazione",
  "Polygon drawing": "Disegno del poligono",
  "positive number only": "Solo numeri positivi",
  "positive number only and minor 100": "Solo numeri positivi e minore di 100",
  "pot. horticolar 90": "pot. orticolo 90",
  "pot. otros cultivos 150": "pot. altri coltivi 150",
  "pot. otros cultivos 90": "pot. altri coltivi 90",
  "potasio foliar": "Potassio fogliare",
  "potencial productivo histórico (ndvi)": "Potenziale produttivo storico (NDVI)",
  "precio": "Prezzo",
  "PRED_TECH": "PredTech",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Intervalli predefiniti da HEMAV che sono generalmente appropriati per la maggior parte dei campi.",
  "prediction": "Previsione",
  "preprocessing": "Preelaborazione",
  "Press this button to add a new field.": "Premi questo pulsante per aggiungere un nuovo campo.",
  "Pressing this button lets you go back to the field view. ": "Premendo questo pulsante puoi tornare alla vista del campo.",
  "Preview": "Anteprima",
  "price Kg": "Prezzo al kg",
  "primer vuelo": "Primo volo",
  "PROD": "Rendimento per unità di superficie",
  "producción esperada": "Produzione prevista",
  "product Kg": "Prodotto Kg",
  "product Kg is mandatory": "Prodotto kg è obbligatorio",
  "Production estimate": "Stima della produzione",
  "production estimate (units)": "Stima della produzione (Kg/Ha)",
  "profile": "Profilo",
  "protein": "Proteina",
  "puccinia_sp_g1": "puccinia sp \"roya\" - g1",
  "puccinia_sp_g2": "puccinia sp \"roya\" - g2",
  "puccinia_sp_g3": "puccinia sp \"roya\" - g3",
  "puccinia_sp_g4": "puccinia sp \"roya\" - g4",
  "puccinia_sp_g5": "puccinia sp \"roya\" - g5",
  "Quick start with Azure": "Inizio rapido con Azure",
  "Random fertilization": "Fertilizzazione casuale",
  "Random seedling": "Semina casuale",
  "Range": "Intervallo",
  "Ranges adjusted to the index": "Intervalli adattati all'indice",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Saranno generati intervalli della stessa dimensione in base ai valori minimi e massimi dell'immagine. Nei casi in cui l'immagine sia molto omogenea, è probabile che non si noti alcuna differenziazione.",
  "realProduction": "Produzione reale",
  "Recipients": "Destinatari",
  "recomendación abonado dosificado": "Prescrizione di dosaggio di fertilizzazione",
  "recomendación abonado zonificado": "Raccomandazione di fertilizzazione zonale",
  "Recomendacion de riego": "Consiglio di irrigazione",
  "recomendación riego": "Raccomandazione per l'irrigazione",
  "Redo Action": "Rifai azione",
  "Related date": "Data correlata",
  "relative nitrogen": "Azoto relativo",
  "Rendimiento graso ( % )": "Rendimento grasso ( % )",
  "report": "Rapporto",
  "Report sent correctly": "Rapporto inviato correttamente",
  "report type": "Tipo",
  "Report type": "Tipo di rapporto",
  "report_type_action_description": "Proposta di trattamenti o azioni da applicare",
  "report_type_annotation_description": "\"Diagnostica\": Analisi sul campo",
  "report_type_inspection_description": "Analisi da STRATI (si chiude una volta analizzato)",
  "report_type_result_description": "Analisi dei risultati della soluzione o trattamento applicato.",
  "reporte agronómico": "Rapporto agronomico",
  "Reported by": "Segnalato da",
  "Reported fields": "Campi segnalati",
  "reports manager": "Gestione rapporti",
  "required": "richiesto",
  "Required": "Richiesto",
  "Requires 3 characters minimun": "Richiede un minimo di 3 caratteri",
  "Reset": "Reimposta",
  "resiembra": "Risemina",
  "resolution": "Risoluzione SHP (m)",
  "resolution (only SHP)": "Risoluzione (solo per SHP)",
  "retención de agua": "Ritenzione idrica",
  "retry": "Riprova",
  "rgb": "RGB",
  "richness": "Ricchezza",
  "riego zonificado": "Irrigazione zonale",
  "riesgo malherbología": "Rischio di infestanti",
  "Ruler": "Righello",
  "SAC": "Saccarosio per unità di superficie",
  "saccharose estimate": "Stima della saccarosio",
  "sample": "Campione",
  "sample type": "Tipo di campione",
  "Sample was deleted.": "Il campione è stato eliminato.",
  "Sample was successfully modified.": "Il campione è stato modificato con successo.",
  "sample_creation_date": "Data di creazione",
  "samples": "Esemplari",
  "satélite": "Satellite",
  "Satellite button tutorial": "Tutorial: Pulsante Satellite",
  "Satellite View": "Vista Satellite",
  "save": "Salva",
  "Save field": "Salva campo",
  "save_": "salva",
  "search": "Cerca",
  "Search": "Cerca",
  "season": "stagione",
  "Season label": "Etichetta della stagione",
  "season name": "Nome della stagione",
  "SEASON_EVOLUTION": "Evoluzione della stagione",
  "Sector": "Settore",
  "sectores target": "Settori obiettivo",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Vedi? Ora, cliccando di nuovo il pulsante ti permette di tornare alla vista satellitare.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Secondo volo",
  "Select": "Seleziona",
  "select a crop and sample": "Seleziona una coltura e un campione",
  "select all": "Seleziona tutto",
  "Select all": "Seleziona tutto",
  "select at least one field": "Seleziona almeno un campo",
  "select fields by": "Seleziona campi per",
  "select fields by variability range": "seleziona campi per intervallo di variabilità",
  "select fields to open temporal comparison": "Seleziona campi per aprire il confronto temporale",
  "select one": "Seleziona uno",
  "select one customer please": "Seleziona un cliente per favore",
  "select one subtype please": "Seleziona un sottotipo per favore",
  "select one type please": "Seleziona un tipo di coltura per favore",
  "select sample base layer and date": "seleziona lo strato di base del campione e la data",
  "select sample location": "seleziona la posizione del campione",
  "select season": "seleziona stagione",
  "selected": "selezionato",
  "Selected fields names": "Nomi dei campi selezionati",
  "Selected fields samples": "Campioni dei campi selezionati",
  "Selection includes too much information, try selecting a shorter date range": "La selezione include troppe informazioni, prova a selezionare un intervallo di date più breve",
  "Send": "Invia",
  "Send report": "Invia rapporto",
  "settings": "Impostazioni",
  "Share fields report": "Condividi rapporto dei campi",
  "show all": "mostra tutto",
  "Show All Layers": "Mostra tutti gli strati",
  "Show current season only": "Mostra solo la stagione corrente",
  "show fields with no data": "Mostra campi senza dati",
  "show filter list": "mostra lista filtri",
  "Show Global Layer": "Mostra strato globale",
  "Show Measure Tools": "Mostra strumenti di misurazione",
  "shp-tooltip": "Questi numeri possono essere modificati in base alle esigenze di utilizzo e sono associati a ciascuna classe di geometria nella tabella degli attributi SHP esportata. È comunemente regolato per stabilire la quantità di fertilizzante o seme da applicare per unità di superficie.",
  "singular selected": "selezionato",
  "Slice tool only works on Polygons and Circle": "Lo strumento di taglio funziona solo su poligoni e cerchi",
  "Slider Tool": "Strumento cursore",
  "soil": "Suolo",
  "soil_type": "Tipo di suolo",
  "sólo para la generación de SHP": "Solo per la generazione di SHP",
  "Sort": "Ordina",
  "sort by date": "ordina per data",
  "Start date": "Data di inizio",
  "static layers": "strati statici",
  "Statistics": "Statistiche",
  "status": "Stato",
  "stem_num": "Numero di steli",
  "Streets distance": "Distanza dalle strade",
  "Subtype": "Sottotipo",
  "Suc/Ha": "Sacc/Ha",
  "sum_of_bases": "Somma delle basi",
  "Superficie ( Ha )": "Superficie ( Ha )",
  "Superficie Sector ( Ha )": "Superficie Settore ( Ha )",
  "surfaceUnit": "Unità di superficie",
  "switch between all samples and current season samples": "alterna tra tutti i campioni e i campioni della stagione corrente",
  "Switch to hybrid map": "Passa alla mappa ibrida",
  "Switch to Mapbox": "Passa alla mappa satellitare2",
  "Switch to satellite map": "Passa alla mappa satellitare",
  "Switch to Terrain map": "Passa alla mappa del terreno",
  "target date": "Data obiettivo",
  "target multiparcela": "Obiettivo multifield",
  "tell us": "Diteci",
  "Temporal Comparison tutorial": "Tutorial: Confronto Temporale",
  "textura del suelo": "Textura del suelo",
  "The date should be in DD/MM/YYYY format": "La data di riferimento deve essere valida nel formato DD/MM/YYYY",
  "The distance must be in meters": "La distanza deve essere in metri",
  "the extra parameters will be removed": "i parametri extra saranno rimossi",
  "the following layers are no longer available in offline mode, download again if necessary": "i seguenti strati non sono più disponibili in modalità offline, scarica di nuovo se necessario",
  "The production must be numeric/decimal": "La produzione deve essere numerica/decimale",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "La colonna di destra mostra la quantità totale da applicare in base al valore inserito per classe, e il totale per parcella nella parte inferiore.",
  "The sample location must be inside one of the fields.": "La posizione del campione deve essere all'interno di uno dei campi.",
  "There are no samples to download": "Non ci sono campioni da scaricare",
  "There is no operated flight on this field.": "Non c'è nessun volo operato su questo campo",
  "Thermal Accumulation": "Accumulo termico",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Questi numeri possono essere modificati in base alle esigenze dell'applicazione e sono associati a ciascuna geometria nella tabella degli attributi dello SHP esportato.",
  "This action will delete the field and all its related data": "Questa azione eliminerà il campo e tutti i suoi dati correlati",
  "this action will modify your field selection": "questa azione modificherà la tua selezione di campo",
  "this action will open field view and lose current field selection": "questa azione aprirà la vista del campo e perderà la selezione del campo corrente",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Questo pulsante ti permette di disegnare linee o poligoni per misurare una distanza o un'area.",
  "This button allows you to switch to the hybrid view.": "Questo pulsante ti permette di passare alla vista ibrida",
  "This can take a long time": "Questo può richiedere molto tempo",
  "This field is not downloaded.": "Questo campo non è stato scaricato",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Questo strumento ti permette di tagliare poligoni. Usa clic singoli per disegnare la linea e un doppio clic per finire di disegnare.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Questo strumento ti permette di disegnare poligoni. Prova a disegnarne uno semplice. Assicurati di chiuderlo!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Questo strumento ti permette di misurare la distanza tra un insieme di punti. Usa clic singoli per impostare i punti e un doppio clic per finire di disegnare.",
  "Those fields are mandatory": "Quei campi sono obbligatori",
  "TIME_COMPARISON_DROP_ZONE": "Rilascia qui...",
  "TINTA BARROCA": "INCHIOSTRO BARROCCO",
  "TINTA FRANCISCA": "INCHIOSTRO FRANCESCO",
  "TINTO": "ROSSO",
  "TINTO CÃO": "ROSSO CAO",
  "tipo de dosificacion": "Tipo di dosaggio",
  "to": "a",
  "today": "oggi",
  "todos": "Tutti",
  "tons of cane - annual vision": "Tonnelate di Canna da Zucchero - Visione Annuale",
  "Total": "Totale",
  "total production": "Produzione totale (Kg)",
  "total_fruits_bigSize": "Totali frutti grandi",
  "total_fruits_mediumSize": "Totali frutti medi",
  "total_fruits_smallSize": "Totali frutti piccoli",
  "total_weight": "Peso totale",
  "total_weight_bigSize": "Peso totale frutti grandi",
  "total_weight_canes": "Peso totale canne",
  "total_weight_mediumSize": "Peso totale frutti medi",
  "total_weight_smallSize": "Peso totale frutti piccoli",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Formazione",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "Prova la nostra app mobile per una migliore esperienza",
  "type": "Coltura",
  "Type": "Tipo di coltura",
  "type here your notes": "digita qui le tue note",
  "Type your text": "Digita il tuo testo",
  "type_sampler": "Tipo di campionatore",
  "Undo Action": "Annulla azione",
  "unidades": "Unità",
  "unitSystem": "Sistema di unità",
  "Unknown": "Sconosciuto",
  "unnamed field": "Campo senza nome",
  "unsaved changes will be lost": "le modifiche non salvate andranno perse",
  "update sample": "aggiorna campione",
  "updated": "Aggiornato",
  "Use this buttons to select which layer to compare.": "Usa questo pulsante per selezionare gli strati che desideri confrontare.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Usa questo strumento per confrontare due voli temporalmente. Per iniziare, trascina uno dei voli in quello con cui vuoi confrontarlo.",
  "Use this tool to freely move the created polygons.": "Usa questo strumento per muovere liberamente i poligoni creati.",
  "used": "usato",
  "Username": "Nome utente",
  "users": "Utenti",
  "Value": "Valore",
  "variabilidad multiparcela": "Variabilità multifield",
  "variabilidad parcela (vra)": "Area a Tasso Variabile (VRA)",
  "variability": "Variabilità",
  "Variedad": "Sottocoltura",
  "vegetative growth": "Crescita vegetativa",
  "vegetative growth per day": "Crescita vegetativa giornaliera accumulata",
  "vegetative growth variability": "Variabilità della crescita vegetativa",
  "Ver detalles": "Visualizza dettagli",
  "very high": "Molto alto",
  "very high level": "Livello molto alto",
  "very low": "Molto basso",
  "very low level": "Livello molto basso",
  "view": "vista",
  "vigor": "VIGORE",
  "vigor a nivel árbol": "vigor / pianta",
  "vigor acumulado dos meses (ndvi)": "Vigore accumulato due mesi (NDVI)",
  "vigor acumulado un año (ndvi)": "Vigore accumulato un anno (NDVI)",
  "vigor vegetativo": "Vigore vegetativo",
  "vigor vegetativo - pcd": "PCD - Vigore Vegetativo",
  "vigor vegetativo (ndvi)": "Vigore vegetativo (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vigore Vegetativo (NDVI) - Fasi Iniziali",
  "vigor zonificado": "Vigor zonificato",
  "VINHÃO": "VINHÃO",
  "Visibility": "Visibilità",
  "Vulnerable area": "Area vulnerabile",
  "wait": "attendi",
  "warning": "Avviso",
  "warning map": "Mappa di avviso",
  "Water level": "Livello dell'acqua",
  "We are processing the data for your flight. Results will be available soon.": "Stiamo elaborando i dati del volo. Attendere per avere tutte le immagini",
  "weeds area": "Area infestanti",
  "week": "Settimana",
  "weight_1": "Peso 1",
  "weight_2": "Peso 2",
  "weight_3": "Peso 3",
  "weight_4": "Peso 4",
  "weight_5": "Peso 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "Con questo strumento puoi disegnare cerchi. Clicca per posizionare il centro e clicca di nuovo per scegliere il raggio.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "Con questo strumento, puoi disegnare un poligono con 3 o più punti per misurarne l'area",
  "without warning": "Senza avviso",
  "Wrong credentials": "Credenziali errate",
  "year": "Anno",
  "years": "Anno",
  "You can change here the information of the new field.": "Qui puoi modificare le informazioni del nuovo campo",
  "You can move this bar to compare the layers temorally.": "Puoi muovere questa barra per confrontare temporalmente gli strati.",
  "You can only compare dates from the same sources": "Puoi confrontare solo date dalle stesse fonti",
  "You can use this button at any moment to undo your last actions.": "Puoi usare questo pulsante in qualsiasi momento per annullare le tue ultime azioni.",
  "You can use this slider to change the opacity of the selected layer.": "Puoi usare questo cursore per cambiare l'opacità dello strato selezionato.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "Non puoi creare un campo perché non hai clienti. Chiedici di aggiungerne alcuni.",
  "You must enter a decimal": "Devi inserire un decimale",
  "You must enter an integer": "Devi inserire un intero",
  "You must select an irrigation type": "Devi selezionare un tipo di irrigazione",
  "You must type a field name": "Devi digitare un nome di campo",
  "You must type a text": "Devi digitare un testo",
  "Your field has been successfully deleted.": "Il tuo campo è stato eliminato con successo",
  "Your LAYERS version has expired. Click to update.": "La tua versione di LAYERS è scaduta. Clicca per aggiornare."
}
