// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Veoma nisko",
  "% Mapa alertas": "% Mapa upozorenja",
  "% Media Fosforo": "% Prosečan fosfor",
  "% Media Nitrogeno": "% Prosečan azot",
  "% Media Potasio": "% Prosečan kalijum",
  "% resiembra": "% ponovna sadnja",
  "0.10 Low": "0.10 Nisko",
  "0.20 Very Low": "0.20 Veoma nisko",
  "0.25 Very Low": "0.25 Veoma nisko",
  "0.30 Low": "0.30 Nisko",
  "0.30 Medium": "0.30 Srednje",
  "0.40  Low": "0.40  Nisko",
  "0.40 Medium": "0.40 Srednje",
  "0.45 High": "0.45 Visoko",
  "0.50 High": "0.50 Visoko",
  "0.55 Medium": "0.55 Srednje",
  "0.65 Very High": "0.65 Veoma visoko",
  "0.70 High": "0.70 Visoko",
  "0.85 Very High": "0.85 Veoma visoko",
  "abonado de cobertera": "Pokrivanje đubrivom",
  "abonado de fondo": "Osnovno đubrenje",
  "abonado de fondo ": "Osnovno đubrenje",
  "abonado dosificado": "Dozirano đubrenje",
  "abonado tasa fija": "Đubrenje fiksnom stopom",
  "abonado zonificado": "Zonirano đubrenje",
  "accumulated vegetative growth": "Akumulirani vegetativni rast",
  "Acre": "Akar",
  "active": "aktivan",
  "Add at least one recipient (+)": "Dodajte barem jednog primaoca (+)",
  "Add Field Button": "Dugme za dodavanje polja",
  "Add Field Tutorial": "Tutorijal za dodavanje polja",
  "add location": "dodaj lokaciju",
  "Add new sample": "Dodaj novi uzorak",
  "add photo": "dodaj fotografiju",
  "added to pending": "dodato u čekanje",
  "Advanced": "Napredno",
  "advanced season parameters": "napredni sezonski parametri",
  "afección": "Oboljenje",
  "afección extensivo": "Šireno oboljenje",
  "Affected Surface": "Pogođena površina",
  "After filling the form, you can draw the field": "Nakon popunjavanja formulara, možete nacrtati polje",
  "agrodelivery": "Agrodostava",
  "all": "sve",
  "ALMENDRA": "Badem",
  "alto estrés hídrico": "Visoki hidrički stres",
  "alto pot prod": "Visoki potencijal proizvodnje",
  "ambiente": "Ambijent",
  "An error occured": "Došlo je do greške",
  "An error occurred from the server. Please try again": "Došlo je do greške na serveru. Molimo pokušajte ponovo",
  "And that's it!": "I to je to!",
  "anexo información": "Prilog informacije",
  "Applied filters": "Primijenjeni filteri",
  "Apply": "Primeni",
  "apply date filter": "primeni filter datuma",
  "Apply selection": "Primijeni selekciju",
  "Are you sure to close the editor?": "Da li ste sigurni da želite zatvoriti uređivač?",
  "Are You sure?": "Da li ste sigurni?",
  "area": "područje",
  "Area": "Površina",
  "ATR": "Ukupni oporavljivi šećer",
  "author": "Autor",
  "Automatic harvest": "Automatska berba",
  "auxiliary files": "Pomoćni fajlovi",
  "back": "nazad",
  "bajo estrés hídrico": "Nizak hidrički stres",
  "bajo pot prod": "Nizak potencijal proizvodnje",
  "Base temperature": "Osnovna temperatura",
  "baseLayer": "Osnovni sloj",
  "Basic": "Osnovno",
  "basic season parameters": "osnovni sezonski parametri",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"pegavost oka\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"pegavost oka\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"pegavost oka\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"pegavost oka\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"pegavost oka\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "I prva i poslednja tačka linije isečka moraju biti izvan poligona/kruga",
  "Buried": "Zakopano",
  "calendar": "Kalendar",
  "calibre": "Kalibar",
  "camera error message": "Izgleda da postoji problem sa dozvolama za kameru",
  "camera_unavailable": "kamera nije dostupna",
  "Can't modify field with active planet subscription": "Ne može se modifikovati polje sa aktivnom planetarnom pretplatom",
  "cancel": "Otkaži",
  "cane_num": "Broj štapova",
  "cantidad": "Količina",
  "capa base": "Osnovni sloj",
  "Capture screenshot": "Snimi sliku ekrana",
  "Change opacity": "Promeni providnost",
  "Choose your layer": "Izaberite svoj sloj",
  "Choose your tool": "Izaberite svoj alat",
  "Circle Drawing": "Crtanje kruga",
  "class": "Klasa",
  "Class values must be numerical": "Vrednosti klasa moraju biti numeričke",
  "classes": "Klase",
  "classes number": "Broj klasa",
  "clear": "Očisti",
  "Clear all": "Očisti sve",
  "clear form": "očisti formular",
  "clear selected fields": "Očisti izabrana polja",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Klikom na ovo dugme, možete promeniti vidljivost izabranog sloja.",
  "clientDeviation": "Odstupanje klijenta",
  "Close": "Zatvori",
  "Close editor": "Zatvori uređivač",
  "Close selection": "Zatvori selekciju",
  "Close the temporal Comparison": "Zatvori vremensku uporedbu",
  "close_selected": "Zatvori izabrano",
  "closed": "zatvoreno",
  "cloud": "Oblak",
  "Cloud Coverage": "Pokrivenost oblaka",
  "comment": "komentar",
  "comp. temporal target": "Vremenska meta komp.",
  "comp. temporal variabilidad": "Vremenska varijabilnost komp.",
  "comparación de conteo": "Upoređivanje brojanja (%)",
  "comparación de estado hídrico": "Upoređivanje hidričkog stanja (%)",
  "comparación de estado hídrico drone": "Upoređivanje hidričkog stanja - Drone (%)",
  "comparación de estado hídrico sat": "Upoređivanje hidričkog stanja - SAT (%)",
  "comparación de estimación de producción": "Upoređivanje procene proizvodnje (%)",
  "comparación de fósforo": "Upoređivanje fosfora (%)",
  "comparación de mapa de alertas": "Upoređivanje mape upozorenja (%)",
  "comparación de mapa de faltas": "Upoređivanje mape nedostataka (%)",
  "comparación de media de conteo": "Upoređivanje prosečnog brojanja (%)",
  "comparación de media de estado hídrico": "Upoređivanje prosečnog hidričkog stanja (%)",
  "comparación de media de fósforo": "Upoređivanje prosečnog fosfora (%)",
  "comparación de media de mapa de alertas": "Upoređivanje prosečne mape upozorenja (%)",
  "comparación de media de mapa de faltas": "Upoređivanje prosečne mape nedostataka (%)",
  "comparación de media de nitrógeno": "Upoređivanje prosečnog azota (%)",
  "comparación de media de potasio": "Upoređivanje prosečnog kalijuma (%)",
  "comparación de media de producción total": "Upoređivanje prosečne ukupne proizvodnje (%)",
  "comparación de media de rendimiento graso": "Upoređivanje prosečnog masnog prinosa (%)",
  "comparación de medias de estimación de producción": "Upoređivanje prosečnih procena proizvodnje (%)",
  "comparación de medias de ndvi": "Upoređivanje prosečnih NDVI (%)",
  "comparación de medias de proteína": "Upoređivanje prosečnih proteina (%)",
  "comparación de medias de variabilidad": "Upoređivanje prosečne varijabilnosti (%)",
  "comparación de ndvi": "Upoređivanje NDVI (%)",
  "comparación de nitrógeno": "Upoređivanje azota (%)",
  "comparación de nitrógeno relativo": "Upoređivanje relativnog azota (%)",
  "comparación de potasio": "Upoređivanje kalijuma (%)",
  "comparación de producción total": "Upoređivanje ukupne proizvodnje (%)",
  "comparación de proteína": "Upoređivanje proteina (%)",
  "comparación de rendimiento graso": "Upoređivanje masnog prinosa (%)",
  "comparación de sacarosa": "Upoređivanje saharoze",
  "comparación de variabilidad": "Upoređivanje varijabilnosti (%)",
  "confirm": "Potvrdi",
  "Congratulations! Your field has been created.": "Čestitamo! Vaše polje je kreirano.",
  "Congratulations! Your field has been edited.": "Čestitamo! Vaše polje je izmenjeno.",
  "Contact": "Kontakt",
  "Contenido de sacarosa ( % )": "Sadržaj saharoze ( % )",
  "conteo - pdf rgb": "brojanje - pdf rgb",
  "conteo - shps": "brojanje - shps",
  "conteo de individuos": "Brojanje pojedinaca",
  "conteo lechugas": "Brojanje salata",
  "continue": "nastavi",
  "Cookies and Privacy": "Колачићи и Приватност",
  "CORN": "KUKURUZ",
  "correction": "Korekcija",
  "Corte nitrogeno": "Sečenje azota",
  "cost": "Trošak",
  "count (units)": "Broj (jedinice)",
  "Counting": "Brojanje",
  "create": "kreiraj",
  "Create New Field": "Kreiraj novo polje",
  "CREATE_RANGES": "Na osnovu histograma slike, kreirajte opsege sa procentom sličnih podataka u svakom opsegu.",
  "created": "Kreirano",
  "crecimiento cultivo": "Rast useva",
  "Crop": "Usev",
  "Crop type": "Tip useva",
  "CROP_INSPECTION": "Inspekcija useva",
  "cultive": "Usev",
  "Cultivo": "Usev",
  "current season": "trenutna sezona",
  "current selection could take a moment to process": "Trenutni izbor može potrajati neko vreme za obradu",
  "Customer": "Kupac",
  "customerProductionEstimation": "Procena proizvodnje kupca",
  "customers": "Kupci",
  "cut": "Sečenje",
  "Cutter": "Rezač",
  "dashboard": "Kontrolna tabla",
  "date": "Datum",
  "DD/MM/YYYY": "DD/MM/YYYY",
  "delete": "obriši",
  "Delete": "Obriši",
  "Delete downloaded report": "Obriši preuzeti izveštaj",
  "delete downloaded sample": "obriši preuzeti uzorak",
  "Delete field": "Obriši polje",
  "delete layers?": "obriši slojeve?",
  "delete sample": "obriši uzorak",
  "delete sample edition": "obriši izdanje uzorka",
  "DeleteField": "Polje je obrisano",
  "Deliverables tutorial": "Tutorijal za isporuke",
  "Description": "Opis",
  "Deselect": "Poništi izbor",
  "deselect all": "Poništi izbor svega",
  "deviation": "Odstupanje",
  "Did you forget your password ?": "Da li ste zaboravili lozinku?",
  "disponible en visor": "Dostupno u pregledaču",
  "do you want to delete": "da li želite da obrišete",
  "documentation": "Dokumentacija",
  "don't show again": "ne prikazuj ponovo",
  "Done": "Završeno",
  "dosificación genérica": "Generička doziranja",
  "Dosis Media Cliente": "Prosečna doza klijenta",
  "Dosis media Propuesta": "Predložena prosečna doza",
  "dosis riego": "Doza navodnjavanja",
  "Download": "Preuzmi",
  "download failed": "Sledeća preuzimanja nisu uspela",
  "Download list": "Preuzmi listu",
  "Download selected fields samples": "Preuzmi uzorke izabranih polja",
  "download selected geometries": "Preuzmi izabrane geometrije",
  "download values": "Preuzmi vrednosti",
  "downloaded": "preuzeto",
  "downloading layers": "preuzimanje slojeva",
  "downloads": "Preuzimanja",
  "Drag and drop your layers to arrange them in the list.": "Prevucite i otpustite svoje slojeve da ih rasporedite na listi.",
  "Drag Fields": "Prevuci polja",
  "Drag your layers": "Prevuci svoje slojeve",
  "Draw Field": "Nacrtaj polje",
  "dron": "Dron",
  "Dron sectores": "Dron sektori",
  "drop here": "ispusti ovde",
  "Dry": "Suvo",
  "dry matter": "Suva materija",
  "dryMatterField": "Suva materija u polju",
  "dryMatterLaboratory": "Suva materija u laboratoriji",
  "dsm": "DSM",
  "earliest harvest date": "Najraniji datum berbe",
  "edit": "uredi",
  "Edit Field": "Uredi polje",
  "Edit geometry": "Uredi geometriju",
  "edit range": "Uredi opseg",
  "Edit values": "Uredi vrednosti",
  "ej 100": "Pr. 100",
  "End Date": "Datum završetka",
  "Equal Interval": "Jednaki Interval",
  "Equidistant ranges": "Jednako udaljeni rasponi",
  "error fetching classes": "Nebo je previše oblačno na izabrani datum. Podesite filter oblaka u kalendaru da biste pronašli vedriji dan.",
  "estado hídrico": "Hidričko stanje",
  "estado hídrico (ndmi)": "Hidričko stanje (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Procena",
  "estimación producción": "Procena proizvodnje",
  "Estimacion produccion ( Tn / Ha )": "Procena proizvodnje ( Tn / Ha )",
  "estimación producción cualitativa": "Kvalitativna procena proizvodnje",
  "estimación proteína": "Procena proteina",
  "Estimacion proteina ( % )": "Procena proteina ( % )",
  "estimación sacarosa": "Procena saharoze",
  "estimación sacarosa ": "Procena saharoze",
  "estimación sacarosa cualitativa": "Kvalitativna procena saharoze",
  "Estimacion Total ( Kg )": "Ukupna procena ( Kg )",
  "estimation gauging": "Procena merenja",
  "estrategia de dosificacion": "Strategija doziranja",
  "estrés hídrico (lws)": "Hidrički stres (LWS)",
  "evolución de NDVI": "Evolucija NDVI",
  "evolution": "evolucija",
  "Ex : comment": "Pr : komentar",
  "Ex : field R02": "Pr : polje R02",
  "Ex : Tresbolillos, row, tree": "Pr : Tresbolillos, red, drvo",
  "expected_final_weight": "Očekivana konačna težina",
  "external_id": "Spoljašnji ID",
  "extra parameters": "dodatni parametri",
  "falhas carreadores": "Greške nosača",
  "falhas internas": "Unutrašnje greške",
  "falhas terraços": "Greške terasa",
  "fat performance": "Masni učinak",
  "faults map": "Mapa grešaka",
  "fdn": "FDN",
  "Fecha de vuelo": "Datum leta",
  "Fecha Plantacion": "Datum sadnje",
  "Feddan": "Feddan",
  "fertilization strategy": "Strategija đubrenja",
  "Fertilizer recommendation": "Preporuka za đubrivo",
  "field": "polje",
  "Field Name": "Ime polja",
  "Field without geometry": "Polje bez geometrije",
  "FIELD_FILTER": "Filteri polja",
  "field_name": "Ime polja",
  "FIELD_TEMPORAL_EVOLUTION": "Vremenska evolucija polja",
  "fields": "Polja",
  "Fields without geometries": "Polja bez geometrija",
  "File cannot be generated due to high amounts of clouds. Please select another date": "Fajl ne može biti generisan zbog velike količine oblaka. Molimo vas, izaberite drugi datum",
  "File generator": "Generator fajlova",
  "File Generator": "Generator fajlova",
  "File name": "Ime fajla",
  "file type": "Tip fajla",
  "Fill plantation date of current field, showing one year of historic data": "Popunite datum sadnje trenutnog polja, prikazujući jednu godinu istorijskih podataka",
  "Fill the field form": "Popunite formular polja",
  "Fill the form to apply for all our services": "Popunite formular da biste se prijavili za sve naše usluge",
  "filtered field": "filtrirano polje",
  "filtered fields": "filtrirana polja",
  "Filters": "Filteri",
  "final_weight": "Konačna težina",
  "fitopatógenos": "Fitopatogeni",
  "Flight Slider": "Klizač leta",
  "FLIGHT_FILTER": "Vremenska uporedba",
  "flights type": "Tip letova",
  "fmsavi": "FMSAVI",
  "for everyone": "za sve",
  "for me": "za mene",
  "forecasts": "Prognoze",
  "formulario de abonado": "Formular za pretplatnike",
  "formulario de dosis": "Formular doze",
  "fósforo foliar": "Folijarni fosfor",
  "fr": "Fr",
  "Fraction": "Razlomak",
  "free": "besplatno",
  "from": "Od",
  "fruits_100_weight": "Težina 100 plodova",
  "fruits_actual1_weight": "Stvarna težina ploda",
  "Fuente de informacion": "Izvor informacija",
  "gallery": "Galerija",
  "gauging date": "Datum merenja",
  "generation error": "Greška u generisanju",
  "geometry": "Geometrija",
  "get a free trial": "dobijte besplatnu probu",
  "Get back to editor": "Vrati se na uređivač",
  "global files": "Preuzmite globalne PDF i CSV fajlove",
  "global layer": "Globalni sloj",
  "gm_cut_number": "Broj sečenja",
  "gm_data_generation": "Datum satelita (nedelje)",
  "gm_day_cycle": "Dani uzgajanja",
  "gm_harvest_estimated_date": "Procenjeni datum berbe (nedelje)",
  "gm_hemav_atr_estimation": "Prognoza TRS",
  "gm_hemav_pol_estimation": "Prognoza polarizacije",
  "gm_hemav_prodph_estimation": "Prognoza prinosa",
  "gm_hemav_realprodph_lastseason": "Stvarni prinos iz prethodne sezone",
  "gm_hemav_sac_estimation": "Prognoza saharoze",
  "gm_luvi_date_generation": "Datum satelita - LUVI (nedelje)",
  "gm_luvi_day_cycle": "Dani uzgajanja (NDVI-LUVI)",
  "gm_luvi_mean": "Vegetativni vigor (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Varijabilnost (NDVI-LUVI)",
  "gm_lws_mean": "Hidrički stres (LWS)",
  "gm_ndmi_date_generation": "Datum satelita - NDMI (nedelje)",
  "gm_ndmi_day_cycle": "Dani uzgajanja (NDMI)",
  "gm_ndre_date_generation": "Datum satelita - NDRE (nedelje)",
  "gm_ndre_day_cycle": "Dani uzgajanja (NDRE)",
  "gm_sar_biomass_cat": "Категоријска биомаса (SAR)",
  "gm_season_label": "Sezona",
  "gm_sub_type_name": "Sorta",
  "gm_type_name": "Usev",
  "go to field": "Idi na polje",
  "grid": "Mreža",
  "harvest date": "Datum berbe",
  "harvest planning": "Planiranje berbe",
  "HARVEST_PLANNING_TOOL": "Alat za planiranje berbe",
  "HARVEST_PREDICTION": "Prognoza berbe",
  "Hectare": "Hektar",
  "Hemav Default": "Podrazumevano Hemav",
  "HEMAV Default": "HEMAV Podrazumevano",
  "Here, you can play with the preview of your different layers.": "Ovde možete igrati sa pregledom vaših različitih slojeva.",
  "hide": "sakrij",
  "Hide All Layers": "Sakrij sve slojeve",
  "hide filter list": "Sakrij listu filtera",
  "Hide Global Layer": "Sakrij globalni sloj",
  "Hide Measure Tools": "Sakrij alate za merenje",
  "high": "Visoko",
  "high level": "Visok nivo",
  "high need": "Visoka potreba",
  "higher clay content": "Veći sadržaj gline",
  "humedad de suelo - beta (%)": "Vlažnost tla - Beta (%)",
  "humedad vegetativa (ndmi)": "Vegetativna vlažnost (NDMI)",
  "Hybrid View": "Hibridni prikaz",
  "hydric status": "Hidrički status - NDMI",
  "hydric status drone": "Hidrički status - Dron",
  "If foliar sampler haven't been made, the date you must enter is the current": "Ako uzorak lišća nije napravljen, datum koji morate uneti je trenutni",
  "If you have two or more flights, you can compare them temporally using this tool.": "Ako imate dva ili više letova, možete ih vremenski uporediti koristeći ovaj alat.",
  "If you have undone an action, you can press this button to redo it.": "Ako ste poništili radnju, možete pritisnuti ovo dugme da je ponovite.",
  "image": "slika",
  "Impacto estimado": "Procenjeni uticaj",
  "In this tab, you can download the generated PDFs.": "Na ovoj kartici možete preuzeti generisane PDF-ove.",
  "individuals_num": "Broj pojedinaca",
  "infection": "Infekcija",
  "info": "Info",
  "Information": "Informacije",
  "informe": "Izveštaj",
  "integración con maquinaria": "Integracija sa mašinama",
  "Invalid credentials": "Nevažeći akreditivi",
  "invalid date": "Nevažeći datum",
  "Invalid email": "Nevažeća email adresa",
  "invest": "Investiraj",
  "Irrigation recommendation": "Preporuka za navodnjavanje",
  "Irrigation type": "Tip navodnjavanja",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "Obično se podešava da se utvrdi količina đubriva ili semena koja će se primeniti po jedinici površine.",
  "item_num": "Broj stavki",
  "Keep selection": "Zadrži selekciju",
  "label": "Oznaka",
  "Label": "Oznaka",
  "languaje": "Jezik",
  "last sample": "Poslednji uzorak",
  "lat": "Geografska širina",
  "layers": "Slojevi",
  "layers estrés hídrico (lws)": "Slojevi hidričkog stresa (LWS)",
  "layers status": "Status slojeva",
  "Legal limitation": "Pravno ograničenje",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"prstenasta mrlja\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"prstenasta mrlja\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"prstenasta mrlja\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"prstenasta mrlja\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"prstenasta mrlja\" - g5",
  "letter only": "Samo slova",
  "linear meters": "Linearni metri",
  "líneas de cultivo": "Linije uzgoja",
  "list": "Lista",
  "loading": "Učitavanje",
  "location": "Lokacija",
  "Log in": "Prijavi se",
  "logout": "Odjavi se",
  "lon": "Geografska dužina",
  "looks like its taking too long": "izgleda da traje predugo",
  "low": "Nisko",
  "low level": "Nizak nivo",
  "low need": "Niska potreba",
  "lower clay content": "Niži sadržaj gline",
  "LUVI variability": "Varijabilnost LUVI",
  "Machines Type": "Tip mašina",
  "main_macronutrient": "Glavni makronutrijent",
  "malas hierbas": "Korovi",
  "malas hierbas - hoja estrecha": "Korovi - uski list",
  "malas hierbas - hoja larga": "Korovi - široki list",
  "malas hierbas - zonificado": "Korovi - zonirano",
  "malas hierbas shps": "Korovi SHP",
  "malas hierbas shps - hoja estrecha": "Korovi SHP - uski list",
  "malas hierbas shps - hoja larga": "Korovi SHP - široki list",
  "malas hierbas shps - zonificado": "Korovi SHP - zonirano",
  "Manage report": "Upravljanje izveštajem",
  "max 2 years": "maksimalno 2 godine",
  "max comparable fields": "Maksimalno uporediva polja",
  "maximo de variacion": "Maksimalna varijacija",
  "máximo vigor dos meses (ndvi)": "Maksimalni vigor dva meseca (NDVI)",
  "máximo vigor un año (ndvi)": "Maksimalni vigor jedna godina (NDVI)",
  "Maximun cloud coverage": "Maksimalna pokrivenost oblaka",
  "mean": "Prosek",
  "Measures tool tutorial": "Tutorijal za alate za merenje",
  "Measures tools": "Alati za merenje",
  "media de faltas": "Prosečne greške",
  "medias": "Mediji",
  "medio pot prod": "Srednji potencijal proizvodnje",
  "medium": "Srednje",
  "medium clay content": "Srednji sadržaj gline",
  "medium level": "Srednji nivo",
  "medium_weight_bigSize": "Prosečna težina velikih plodova",
  "medium_weight_mediumSize": "Prosečna težina srednjih plodova",
  "medium_weight_smallSize": "Prosečna težina malih plodova",
  "Mensual limit": "Mesečno ograničenje",
  "Message": "Poruka",
  "METEOROLOGICAL_STATISTICS": "Meteorološka statistika",
  "mode": "Režim",
  "model type": "Tip modela",
  "Move the slider": "Pomeri klizač",
  "msavi": "MSAVI",
  "N_BUNCHES": "Broj grozdova",
  "Name": "Ime",
  "nameless field": "Polje bez imena",
  "Natural Breaks (Jenks)": "Prirodni Prelazi (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "Stvarni NDVI",
  "ndvi-curvas de nivel": "NDVI-Krive nivoa",
  "Network request failed": "Zahtev mreže nije uspeo",
  "new sample": "novi uzorak",
  "New sample created.": "Kreiran novi uzorak.",
  "NITRO": "Azot",
  "nitrogen chlorophyll (ndre)": "Azot-hlorofil (NDRE)",
  "nitrogen_assimilable": "Asimilabilni azot",
  "nitrógeno foliar": "Folijarni azot",
  "Nivel de estres": "Nivo stresa",
  "no data": "Nema podataka",
  "no data available": "Nema dostupnih podataka",
  "no data with current selection": "Nema podataka sa trenutnim izborom",
  "No fields selected": "Nijedno polje nije izabrano",
  "no fields to clear": "Nema polja za čišćenje",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "Нема слика за овај дан.",
  "Not a user yet ?": "Još uvek niste korisnik?",
  "not enough data to display this chart": "Nema dovoljno podataka za prikaz ovog grafikona",
  "Not supported by browser": "Nije podržano od strane pretraživača",
  "notes": "beleške",
  "number only": "Samo brojevi",
  "Numero vuelo": "Broj leta",
  "observations": "Napomene",
  "offline access": "pristup van mreže",
  "offline mode": "Van mreže",
  "oil_yield": "Prinos ulja",
  "ok": "U redu",
  "Once you are done, click this button to save the changes.": "Kada završite, kliknite na ovo dugme da sačuvate promene.",
  "open": "otvori",
  "Open": "Otvori",
  "Open selection": "Otvori selekciju",
  "open temporal comparison": "Otvori vremensku uporedbu",
  "open_selected": "Otvori izabrano",
  "optimize": "Optimizuj",
  "or": "ili",
  "Order by a - z": "Poredaj po a - z",
  "Order by distance to the field": "Poredaj po udaljenosti do polja",
  "Order by z - a": "Poredaj po z - a",
  "ourDeviation": "Naše odstupanje",
  "ourProductionEstimation": "Naša procena proizvodnje",
  "Parcela": "Parcela",
  "Password": "Lozinka",
  "pb": "Pb",
  "pcd": "PCD",
  "pcd arbol": "PCD drvo",
  "pcd interpolado": "Interpolirani PCD",
  "pcd row": "PCD red",
  "pdf": "PDF",
  "pending": "u toku",
  "phospohorus": "Fosfor",
  "phosporus": "Fosfor (ppm)",
  "photo": "fotografija",
  "photoid": "ID fotografije",
  "photoId": "Fotografija",
  "picture_path": "Putanja slike",
  "plague": "Kuga",
  "planned dosage": "Planirana doza",
  "Plant emitters": "Emiteri biljaka",
  "plantation date": "Datum sadnje",
  "Plantation date": "Datum sadnje",
  "plantation_end_date_range": "Opseg datuma sadnje",
  "plantation_start_date_range": "Opseg datuma sadnje",
  "Plantations": "Plantaže",
  "plants": "Biljke",
  "Plants distance": "Razdaljina biljaka",
  "plants_density_range": "Opseg gustine biljaka",
  "Please wait or order one to have data to visualized.": "Molimo sačekajte ili naručite jedan da biste imali podatke za vizualizaciju.",
  "Please, choose a field variety": "Molimo izaberite sortu polja",
  "Please, choose the field owner": "Molimo izaberite vlasnika polja",
  "plural selected": "izabrano",
  "POL": "Polarizacija",
  "Polygon drawing": "Crtanje poligona",
  "positive number only": "Samo pozitivni brojevi",
  "positive number only and minor 100": "Samo pozitivni brojevi i manje od 100",
  "pot. horticolar 90": "pot. hortikulturni 90",
  "pot. otros cultivos 150": "pot. drugi usevi 150",
  "pot. otros cultivos 90": "pot. drugi usevi 90",
  "potasio foliar": "Folijarni kalijum",
  "potencial productivo histórico (ndvi)": "Istorijski proizvodni potencijal (NDVI)",
  "precio": "Cena",
  "PRED_TECH": "PRED_TECH",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Preddefinované rozsahy podľa HEMAV, ktoré sú vo všeobecnosti vhodné pre väčšinu polí.",
  "prediction": "Predviđanje",
  "preprocessing": "Predprocesiranje",
  "Press this button to add a new field.": "Pritisnite ovo dugme da biste dodali novo polje.",
  "Pressing this button lets you go back to the field view. ": "Pritiskom na ovo dugme vraćate se na prikaz polja.",
  "Preview": "Pregled",
  "price Kg": "Cena po kg",
  "primer vuelo": "Prvi let",
  "PROD": "Prinos po površinskoj jedinici",
  "producción esperada": "Očekivana proizvodnja",
  "product Kg": "Proizvod Kg",
  "product Kg is mandatory": "Proizvod Kg je obavezan",
  "Production estimate": "Procena proizvodnje",
  "production estimate (units)": "Procena proizvodnje (Kg/Ha)",
  "profile": "Profil",
  "protein": "Protein",
  "puccinia_sp_g1": "puccinia sp \"rdja\" - g1",
  "puccinia_sp_g2": "puccinia sp \"rdja\" - g2",
  "puccinia_sp_g3": "puccinia sp \"rdja\" - g3",
  "puccinia_sp_g4": "puccinia sp \"rdja\" - g4",
  "puccinia_sp_g5": "puccinia sp \"rdja\" - g5",
  "Quick start with Azure": "Brzi početak sa Azure",
  "Random fertilization": "Nasumično đubrenje",
  "Random seedling": "Nasumično sadnja",
  "Range": "Opseg",
  "Ranges adjusted to the index": "Opsezi prilagođeni indeksu",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Odstupanja iste veličine će biti generisana prema minimalnoj i maksimalnoj vrednosti slike. U slučajevima kada je slika vrlo homogena, verovatno je da neće biti vidljive razlike.",
  "realProduction": "Stvarna proizvodnja",
  "Recipients": "Primaoci",
  "recomendación abonado dosificado": "Preporuka za dozirano đubrenje",
  "recomendación abonado zonificado": "Preporuka za zonirano đubrenje",
  "Recomendacion de riego": "Preporuka za navodnjavanje",
  "recomendación riego": "Preporuka za navodnjavanje",
  "Redo Action": "Ponovi radnju",
  "Related date": "Povezani datum",
  "relative nitrogen": "Relativni azot",
  "Rendimiento graso ( % )": "Masni učinak ( % )",
  "report": "Izveštaj",
  "Report sent correctly": "Izveštaj uspešno poslat",
  "report type": "Tip izveštaja",
  "Report type": "Tip izveštaja",
  "report_type_action_description": "Predlog tretmana ili akcija koje treba primeniti",
  "report_type_annotation_description": "Dijagnostika: Analiza na terenu",
  "report_type_inspection_description": "Analiza iz slojeva (zatvara se jednom kada je analizirano)",
  "report_type_result_description": "Analiza rezultata primenjenog rešenja ili tretmana.",
  "reporte agronómico": "Agronomski izveštaj",
  "Reported by": "Prijavio",
  "Reported fields": "Prijavljena polja",
  "reports manager": "Izveštaji",
  "required": "obavezno",
  "Required": "Obavezno",
  "Requires 3 characters minimun": "Zahteva minimum 3 karaktera",
  "Reset": "Resetuj",
  "resiembra": "Ponovna sadnja",
  "resolution": "Rezolucija SHP (m)",
  "resolution (only SHP)": "Rezolucija (samo za SHP)",
  "retención de agua": "Zadržavanje vode",
  "retry": "ponovi",
  "rgb": "RGB",
  "richness": "Bogatstvo",
  "riego zonificado": "Zonirano navodnjavanje",
  "riesgo malherbología": "Rizik od korova",
  "Ruler": "Lenjir",
  "SAC": "Saharoza po površinskoj jedinici",
  "saccharose estimate": "Procena saharoze",
  "sample": "Uzorak",
  "sample type": "tip uzorka",
  "Sample was deleted.": "Uzorak je obrisan.",
  "Sample was successfully modified.": "Uzorak je uspešno izmenjen.",
  "sample_creation_date": "Datum kreiranja uzorka",
  "samples": "Uzorci",
  "satélite": "Satelit",
  "Satellite button tutorial": "Tutorijal za satelitsko dugme",
  "Satellite View": "Satelitski prikaz",
  "save": "Sačuvaj",
  "Save field": "Sačuvaj polje",
  "save_": "sačuvaj",
  "search": "Pretraži",
  "Search": "Pretraži",
  "season": "sezona",
  "Season label": "Oznaka sezone",
  "season name": "ime sezone",
  "SEASON_EVOLUTION": "Evolucija sezone",
  "Sector": "Sektor",
  "sectores target": "Ciljni sektori",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Vidite? Sada, klikom na dugme ponovo, možete se vratiti na satelitski prikaz.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Drugi let",
  "Select": "Izaberi",
  "select a crop and sample": "izaberite usev i uzorak",
  "select all": "izaberi sve",
  "Select all": "Izaberi sve",
  "select at least one field": "izaberite barem jedno polje",
  "select fields by": "izaberi polja po",
  "select fields by variability range": "izaberi polja po opsegu varijabilnosti",
  "select fields to open temporal comparison": "Izaberi polja za otvaranje vremenske uporedbe",
  "select one": "izaberi jedno",
  "select one customer please": "molimo izaberite jednog kupca",
  "select one subtype please": "molimo izaberite jednu podvrstu",
  "select one type please": "molimo izaberite jedan tip",
  "select sample base layer and date": "izaberite osnovni sloj uzorka i datum",
  "select sample location": "izaberite lokaciju uzorka",
  "select season": "izaberi sezonu",
  "selected": "izabrano",
  "Selected fields names": "Izabrana polja",
  "Selected fields samples": "Uzorci izabranih polja",
  "Selection includes too much information, try selecting a shorter date range": "Selekcija uključuje previše informacija, pokušajte izabrati kraći opseg datuma",
  "Send": "Pošalji",
  "Send report": "Pošalji izveštaj",
  "settings": "Postavke",
  "Share fields report": "Podeli izveštaj polja",
  "show all": "prikaži sve",
  "Show All Layers": "Prikaži sve slojeve",
  "Show current season only": "Prikaži samo trenutnu sezonu",
  "show fields with no data": "Prikaži polja bez podataka",
  "show filter list": "Prikaži listu filtera",
  "Show Global Layer": "Prikaži globalni sloj",
  "Show Measure Tools": "Prikaži alate za merenje",
  "shp-tooltip": "Ова бројеви могу бити промењени у складу са потребама коришћења и повезани су са сваким класом геометрије у извезеној табели атрибута SHP. Уобичајено се прилагођавају да се установи количина ђубрива или семена која ће се применити по јединици површине.",
  "singular selected": "izabrano",
  "Slice tool only works on Polygons and Circle": "Alat za sečenje radi samo na poligonima i krugovima",
  "Slider Tool": "Alat klizač",
  "soil": "Zemlja",
  "soil_type": "Tip tla",
  "sólo para la generación de SHP": "Samo za generisanje SHP",
  "Sort": "Sortiraj",
  "sort by date": "sortiraj po datumu",
  "Start date": "Datum početka",
  "static layers": "statički slojevi",
  "Statistics": "Statistika",
  "status": "Status",
  "stem_num": "Broj stabljika",
  "Streets distance": "Razdaljina ulica",
  "Subtype": "Podtip",
  "Suc/Ha": "Saharoza/Ha",
  "sum_of_bases": "Zbir baza",
  "Superficie ( Ha )": "Površina ( Ha )",
  "Superficie Sector ( Ha )": "Površina sektora ( Ha )",
  "surfaceUnit": "Jedinica površine",
  "switch between all samples and current season samples": "prebacivanje između svih uzoraka i uzoraka trenutne sezone",
  "Switch to hybrid map": "Prebaci se na hibridnu mapu",
  "Switch to Mapbox": "Prebaci se na satelitsku2 mapu",
  "Switch to satellite map": "Prebaci se na satelitsku mapu",
  "Switch to Terrain map": "Prebaci se na terensku mapu",
  "target date": "Ciljni datum",
  "target multiparcela": "Ciljna višepoljna",
  "tell us": "Recite nam",
  "Temporal Comparison tutorial": "Tutorijal za vremensku uporedbu",
  "textura del suelo": "Tekstura tla",
  "The date should be in DD/MM/YYYY format": "Datum treba da bude u formatu DD/MM/YYYY",
  "The distance must be in meters": "Razdaljina mora biti u metrima",
  "the extra parameters will be removed": "dodatni parametri će biti uklonjeni",
  "the following layers are no longer available in offline mode, download again if necessary": "sledeći slojevi više nisu dostupni u režimu van mreže, preuzmite ponovo ako je potrebno",
  "The production must be numeric/decimal": "Proizvodnja mora biti numerička/decimalna",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "Desna kolona prikazuje ukupnu količinu koja se primenjuje prema vrednosti unesenoj po klasi, a ukupno po parceli na dnu.",
  "The sample location must be inside one of the fields.": "Lokacija uzorka mora biti unutar jednog od polja.",
  "There are no samples to download": "Nema uzoraka za preuzimanje",
  "There is no operated flight on this field.": "Na ovom polju nema izvršenih letova.",
  "Thermal Accumulation": "Termalna akumulacija",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Ovi brojevi se mogu menjati prema potrebama aplikacije i povezuju se sa svakom geometrijom u tabeli atributa izvezenog SHP-a.",
  "This action will delete the field and all its related data": "Ova akcija će obrisati polje i sve povezane podatke",
  "this action will modify your field selection": "ova akcija će izmeniti vaš izbor polja",
  "this action will open field view and lose current field selection": "ova akcija će otvoriti pregled polja i izgubiti trenutni izbor polja",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Ovo dugme vam omogućava da crtate linije ili poligone kako biste izmerili rastojanje ili površinu.",
  "This button allows you to switch to the hybrid view.": "Ovo dugme vam omogućava da pređete na hibridni prikaz.",
  "This can take a long time": "Ovo može potrajati dugo",
  "This field is not downloaded.": "Ovo polje nije preuzeto",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Ovaj alat vam omogućava da sečete poligone. Koristite jedan klik za crtanje linije i dvostruki klik za završetak crtanja.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Ovaj alat vam omogućava da crtate poligone. Pokušajte nacrtati jednostavan. Obavezno ga zatvorite!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Ovaj alat vam omogućava da merite rastojanje između niza tačaka. Koristite jedan klik za postavljanje tačaka i dvostruki klik za završetak crtanja.",
  "Those fields are mandatory": "Ta polja su obavezna",
  "TIME_COMPARISON_DROP_ZONE": "Ispusti ovde...",
  "TINTA BARROCA": "BAROKNA TINTA",
  "TINTA FRANCISCA": "FRANCUSKA TINTA",
  "TINTO": "CRVENO",
  "TINTO CÃO": "CRVENI PAS",
  "tipo de dosificacion": "Tip doziranja",
  "to": "Do",
  "today": "danas",
  "todos": "Svi",
  "tons of cane - annual vision": "Tone šećerne trske - Godišnji pregled",
  "Total": "Ukupno",
  "total production": "Ukupna proizvodnja (Kg)",
  "total_fruits_bigSize": "Ukupno veliki plodovi",
  "total_fruits_mediumSize": "Ukupno srednji plodovi",
  "total_fruits_smallSize": "Ukupno mali plodovi",
  "total_weight": "Ukupna težina",
  "total_weight_bigSize": "Ukupna težina velikih plodova",
  "total_weight_canes": "Ukupna težina štapova",
  "total_weight_mediumSize": "Ukupna težina srednjih plodova",
  "total_weight_smallSize": "Ukupna težina malih plodova",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Obuka",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "Probajte našu mobilnu aplikaciju za bolje iskustvo",
  "type": "Usev",
  "Type": "Tip useva",
  "type here your notes": "ovde unesite svoje beleške",
  "Type your text": "Unesite svoj tekst",
  "type_sampler": "Tip uzorka",
  "Undo Action": "Poništi radnju",
  "unidades": "Jedinice",
  "unitSystem": "Sistem jedinica",
  "Unknown": "Nepoznato",
  "unnamed field": "Polje bez imena",
  "unsaved changes will be lost": "Nesačuvane promene će biti izgubljene",
  "update sample": "ažuriraj uzorak",
  "updated": "Ažurirano",
  "Use this buttons to select which layer to compare.": "Koristite ovo dugme da izaberete koje slojeve želite da uporedite.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Koristite ovaj alat da uporedite dva leta vremenski. Za početak, prevucite jedan od letova u onaj sa kojim želite da ga uporedite.",
  "Use this tool to freely move the created polygons.": "Koristite ovaj alat da slobodno pomerate kreirane poligone.",
  "used": "korišćeno",
  "Username": "Korisničko ime",
  "users": "Korisnici",
  "Value": "Vrednost",
  "variabilidad multiparcela": "Varijabilnost višepolja",
  "variabilidad parcela (vra)": "Varijabilna stopa područja (VRA)",
  "variability": "Varijabilnost",
  "Variedad": "Sorta",
  "vegetative growth": "Vegetativni rast",
  "vegetative growth per day": "Akumulirani vegetativni rast po danu",
  "vegetative growth variability": "Varijabilnost vegetativnog rasta",
  "Ver detalles": "Pogledaj detalje",
  "very high": "Veoma visoko",
  "very high level": "Veoma visok nivo",
  "very low": "Veoma nisko",
  "very low level": "Veoma nizak nivo",
  "view": "prikaz",
  "vigor": "VIGOR",
  "vigor a nivel árbol": "vigor / biljka",
  "vigor acumulado dos meses (ndvi)": "Akumulirani vigor dva meseca (NDVI)",
  "vigor acumulado un año (ndvi)": "Akumulirani vigor jedna godina (NDVI)",
  "vigor vegetativo": "Vegetativni vigor",
  "vigor vegetativo - pcd": "PCD - Vegetativni vigor",
  "vigor vegetativo (ndvi)": "Vegetativni vigor (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vegetativni vigor (NDVI) - Rane faze",
  "vigor zonificado": "Zonirani vigor",
  "VINHÃO": "VINHÃO",
  "Visibility": "Vidljivost",
  "Vulnerable area": "Osetljivo područje",
  "wait": "čekaj",
  "warning": "Upozorenje",
  "warning map": "Mapa upozorenja",
  "Water level": "Nivo vode",
  "We are processing the data for your flight. Results will be available soon.": "Obrađujemo podatke za vaš let. Rezultati će uskoro biti dostupni.",
  "weeds area": "Područje korova",
  "week": "Nedelja",
  "weight_1": "Težina 1",
  "weight_2": "Težina 2",
  "weight_3": "Težina 3",
  "weight_4": "Težina 4",
  "weight_5": "Težina 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "Sa ovim alatom možete crtati krugove. Kliknite da postavite centar, a zatim ponovo kliknite da odaberete radijus.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "Sa ovim alatom možete nacrtati poligon sa 3 ili više tačaka da biste izmerili njegovu površinu.",
  "without warning": "Bez upozorenja",
  "Wrong credentials": "Pogrešni akreditivi",
  "year": "Godina",
  "years": "Godine",
  "You can change here the information of the new field.": "Ovde možete promeniti informacije o novom polju",
  "You can move this bar to compare the layers temorally.": "Možete pomeriti ovu traku da uporedite slojeve vremenski.",
  "You can only compare dates from the same sources": "Možete uporediti samo datume iz istih izvora",
  "You can use this button at any moment to undo your last actions.": "Ovo dugme možete koristiti u bilo kom trenutku da poništite svoje poslednje radnje.",
  "You can use this slider to change the opacity of the selected layer.": "Ovaj klizač možete koristiti za promenu providnosti izabranog sloja.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "Ne možete kreirati polje jer nemate kupce. Molimo vas da nas pitate za dodavanje.",
  "You must enter a decimal": "Morate uneti decimalni broj",
  "You must enter an integer": "Morate uneti ceo broj",
  "You must select an irrigation type": "Morate izabrati tip navodnjavanja",
  "You must type a field name": "Morate uneti ime polja",
  "You must type a text": "Morate uneti tekst",
  "Your field has been successfully deleted.": "Vaše polje je uspešno obrisano",
  "Your LAYERS version has expired. Click to update.": "Vaša verzija LAYERS-a je istekla. Kliknite za ažuriranje."
}
